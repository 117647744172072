import React, { useEffect, useRef, useState } from "react";
import BaseCardTitle from "core-ui/BaseCardTitle/BaseCardTitle";
import BaseCard from "core-ui/BaseCard/BaseCard";
import { Box, FormHelperText, Typography, useMediaQuery } from "@mui/material";
import { publicAssetsPath } from "utils/Paths/Paths";
import axios from "axios";
import UploaderProgressCard from "core-ui/BaseUploadFile/UploaderProgressCard/UploaderProgressCard";
import { useTheme } from "@emotion/react";

import "./BaseUploadFile.scss";
import { _void } from "utils/Objects/Objects";
import ErrorCodes from "services/StaticLookup/ServerCodes/ErrorCodes";
import { validateFileExtension, validateFileSize } from "utils/File/File";

const BaseFileInput = (props) => {
  const { breakpoints } = useTheme();
  const sm = useMediaQuery(breakpoints.down("sm"));

  const textSX = {
    fontSize: sm ? "1.2rem" : "1.4rem",
    color: "grayDark.color12",
    firstLine: {
      fontSize: "1.5rem",
    },
  };

  const wrapperRef = useRef();

  const [controller, setController] = useState();
  const [isFileUploading, setIsFileUploading] = useState(true);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [progress, setProgress] = useState(0);

  const [fileList, setFileList] = useState(null);

  const onDragEnter = () => {
    wrapperRef.current.classList.add("dragover");
  };
  const onDragLeave = () => {
    wrapperRef.current.classList.remove("dragover");
  };
  const onDrop = () => {
    wrapperRef.current.classList.remove("dragover");
  };

  const abortUploadHandler = () => {
    if (props.disabled) return;
    setFileList(null);
    props.onChange(null);
    controller?.abort();
  };

  const { isSuccess, isError } = props;

  useEffect(() => {
    if (isSuccess) {
      abortUploadHandler();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (props?.uploadedFile?.file) {
      setIsFileUploaded(true);
      setIsFileUploading(false);

      const controller = new AbortController();
      setController(controller);

      setFileList(props.uploadedFile.file);
    } else setFileList(null);
  }, [props.uploadedFile?.file]);

  const validateFile = (file) => {
    if (!file) return;
    ////
    const isValidSize = validateFileSize(file.size, props.maxSize);
    if (!isValidSize) {
      props.onChange({
        error: ErrorCodes.FILE_TOO_LARGE,
      });
      return false;
    }

    const isValidExtension = validateFileExtension(
      file,
      props.availableExtensions
    );

    if (!isValidExtension) {
      props.onChange({
        error: ErrorCodes.WRONG_FILE_EXTENSION,
      });
      return false;
    }

    return true;
  };

  const onFileDrop = async (e) => {
    setIsFileUploaded(false);
    setIsFileUploading(true);

    const controller = new AbortController();

    setController(controller);

    const newFile = e.target.files[0];

    const isValidateFile = validateFile(newFile);

    const formData = new FormData();

    formData.append("file", newFile);

    if (newFile && isValidateFile) {
      const updatedList = [newFile][0];
      setFileList(updatedList);

      const config = {
        signal: controller.signal,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      };

      const postFileURL = "https://httpbin.org/post";

      await axios
        .post(postFileURL, newFile, config)
        .then((res) => {
          props.onChange({ file: updatedList });
          setIsFileUploaded(true);
          setIsFileUploading(false);
        })
        .catch((err) => {});
    }
  };

  const disableClass = !props.disabled ? "" : "uploader__wrapper--disabled";

  return (
    <Box className={`uploader`}>
      {props.title && <BaseCardTitle>{props.title}</BaseCardTitle>}
      {props.description && (
        <Typography
          sx={{
            color: "grayDark.color",
            fontSize: "1.4rem",
            fontWeight: "bold",
            mt: "3rem",
            mb: "2rem",
          }}
        >
          {props.description}
        </Typography>
      )}

      <Box
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={`uploader__wrapper ${disableClass}`}
      >
        {!fileList ? (
          <BaseCard variant={"upload"}>
            <input
              type="file"
              onChange={onFileDrop}
              name={props.name}
              disabled={props.disabled}
            />

            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  src={publicAssetsPath("icons/upload.svg")}
                  width="auto"
                  height={sm ? "15px" : "25rem"}
                />
                {!sm && (
                  <Typography sx={[textSX.firstLine, { ml: "1rem" }]}>
                    قم بسحب وافلات الملف او
                  </Typography>
                )}
                <Typography
                  sx={[
                    textSX.firstLine,
                    {
                      color: "text.tags.blueSoft",
                      fontWeight: "bold",
                      ml: "1rem",
                    },
                  ]}
                >
                  تصفح الملفات{" "}
                </Typography>
              </Box>
              <Box mt={sm ? "1rem" : ""} sx={{ flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={textSX}
                  >{`الحد الأعلى للملفات هو `}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={textSX}>{`${
                      props.maxSize?.unit?.toUpperCase() || "GB"
                    }`}</Typography>
                    <Typography sx={textSX}>{`${
                      props.maxSize.size || "1"
                    }`}</Typography>
                  </Box>
                </Box>
                <Typography sx={textSX}>
                  {`الصيغ المتاحة: ${props.displayAvailableExtensions}`}
                </Typography>
              </Box>
            </Box>
          </BaseCard>
        ) : (
          <UploaderProgressCard
            fileList={fileList}
            abortUploadHandler={abortUploadHandler}
            isFileUploading={isFileUploading}
            isFileUploaded={isFileUploaded}
            onSubmit={props.onSubmit}
            isSubmitting={props.isSubmitting}
            readOnly={props.readOnly}
            disabled={props.disabled}
            progress={progress}
          />
        )}
      </Box>
      {props.errors?.[props.name] && (
        <Box className={"base-dropdown__error"}>
          <FormHelperText variant="error" error sx={{ fontSize: "1.1rem" }}>
            {props.errors[props.name].message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
};

export default BaseFileInput;
