import { Box } from "@mui/material";
import BaseButton from "core-ui/BaseButton/BaseButton";
import BaseModal from "core-ui/BaseModal/BaseModal";
import BaseUploadFile from "core-ui/BaseUploadFile/BaseUploadFile";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AdminAddAttachment = (props) => {
  const { t } = useTranslation(["dashboard"]);

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  return (
    <BaseModal
      show={props.show}
      py={"3rem"}
      px={"3rem"}
      onHide={props.onHide}
      title={props.title}
    >
      <BaseUploadFile
        maxSize={{ size: 1, unit: "MB" }}
        control={control}
        name={props.name}
        errors={errors}
        onSubmit={props.onSubmit}
        availableExtensions={["xls", "xlsx"]}
        displayAvailableExtensions={"XML AND XLSX (Excel)"}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-end",
          flexWrap: "wrap",
          mt: 5,
        }}
      >
        <BaseButton
          sx={{
            minWidth: "12.2rem",
          }}
          variant={"secondary"}
          onClick={props.onHide}
        >
          {t("إلغاء")}
        </BaseButton>
      </Box>
    </BaseModal>
  );
};

export default AdminAddAttachment;
